import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root',
})
export class ResetPassService {
  private httpClient = inject(HttpClient);

  constructor() {}

  requestResetCode(email: string) {
    return this.httpClient.post<void>(
      environment.API_BASE_URL +
        'users/request-password-reset' +
        '?email=' +
        email,
      {},
      { observe: 'response' }
    );
  }

  resetPassword(code: string, password: string) {
    return this.httpClient.post<void>(
      environment.API_BASE_URL + 'users/reset-password-code',
      { code, password },
      { observe: 'response' }
    );
  }

  verifyResetCode(code: string) {
    return this.httpClient.post<void>(
      environment.API_BASE_URL +
        'users/verify-reset-password-code' +
        '?code=' +
        code,
      {},
      { observe: 'response' }
    );
  }
}
